/* eslint-disable import/prefer-default-export */
/**
 * Download blob as a file.
 */
export const downloadFile = (blob, filename) => {
  const url = URL.createObjectURL(new Blob([blob]));
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
};
